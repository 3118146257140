import React from "react";

const About = () => {
  return (
    <main className='bg-gray-100 text-slate-950'>
      {/* Intro Section */}
      <section className='py-16 px-6 bg-slate-950 text-white text-center'>
        <div className='container mx-auto'>
          <h1 className='text-4xl font-bold mb-4'>
            WE ARE ROUND TABLE 37 BODØ
          </h1>
          <p className='text-lg text-white max-w-3xl mx-auto'>
            Round Table 37 Bodø, founded in 1969, is the local club who makes up
            a part of Round Table Norway (1947) and further Round Table
            International (1927).
          </p>
        </div>
      </section>

      {/* Mission Statement Section */}
      <section className='py-16 px-6'>
        <div className='container mx-auto text-center space-y-5'>
          <h2 className='text-3xl font-bold mb-6'>Our Mission</h2>
          <p className='text-lg text-gray-700 max-w-3xl mx-auto'>
            We believe in bringing about change and excellence in ourselves and
            our community.
          </p>
          <p className='text-lg text-gray-700 max-w-3xl mx-auto'>
            Our motto is Adopt, Adapt, Improve and we bring young men aged
            between 20 and 40 together, to challenge, inspire and learn from
            each other.
          </p>
          <p className='text-lg text-gray-700 max-w-3xl mx-auto'>
            We share ideas openly with trust, and empower each individual to
            make a positive impact at home, work and in his community. We call
            it Tabling and we do this locally where we meet regularly. We plan
            and organise meetings and events for ourselves and others that focus
            on personal development, fun & fellowship and community service.
            Together we form an international network of bright young men.
          </p>
        </div>
      </section>

      {/* History Section */}
      <section className='py-16 px-6 bg-gray-200'>
        <div className='container mx-auto text-center'>
          <h2 className='text-3xl font-bold mb-8'>Our History</h2>
          <div className='space-y-8'>
            {/* Milestone 1 */}
            <div>
              <h3 className='text-2xl font-bold'>Founded: January 1969</h3>
              <p className='text-gray-700'>
                RT37 Bodø was officially established, marking the beginning of a
                rich history.
              </p>
            </div>
            {/* Milestone 2 */}
            <div>
              <h3 className='text-2xl font-bold'>
                Hosted National Annual General Meetings
              </h3>
              <p className='text-gray-700'>
                RT37 Bodø proudly hosted the national annual general meetings in
                1974 and 1983.
              </p>
            </div>
            {/* Milestone 3 */}
            <div>
              <h3 className='text-2xl font-bold'>Club of the Year 2019/2020</h3>
              <p className='text-gray-700'>
                Awarded «Club of the Year» by Round Table Norway for exceptional
                achievements.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className='py-16 px-6 text-center bg-slate-950 text-white'>
        <div className='container mx-auto'>
          <h2 className='text-3xl font-bold mb-6'>Get Involved</h2>
          <p className='text-lg mb-6'>
            Want to contribute to our mission? Join us today and make a
            difference in the community.
          </p>
          <a
            href='https://www.roundtable.no/bli-medlem'
            className='px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200'>
            Apply
          </a>
        </div>
      </section>
    </main>
  );
};

export default About;
