import React, { useEffect, useRef } from "react";
import eventsData from "../data/eventsData";
import { useLocation } from "react-router-dom";

const Events = () => {
  const location = useLocation();
  const eventRefs = useRef({});

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = eventRefs.current[id];
      if (element) {
        const offset = 100;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <main className='bg-gray-100 text-slate-950'>
      <section className='py-16 px-6 bg-slate-950 text-white text-center'>
        <div className='container mx-auto'>
          <h1 className='text-4xl font-bold mb-4'>Upcoming Events</h1>
          <p className='text-lg text-white max-w-3xl mx-auto'>
            Round Table 37 Bodø, founded in 1969, is the local club who makes up
            a part of Round Table Norway (1947) and further Round Table
            International (1927).
          </p>
        </div>
      </section>

      {/* Events List */}
      <section className='py-16 px-6'>
        <div className='container mx-auto max-w-4xl'>
          {eventsData.map((eventsData, index) => (
            <div
              key={index}
              id={eventsData.id}
              ref={(el) => (eventRefs.current[eventsData.id] = el)}
              className={`flex flex-col-reverse pb-12 border-b-2 border-gray-400 lg:flex-row ${
                index % 2 === 0 ? "lg:flex-row-reverse" : ""
              } items-center mb-16`}>
              {/* Text Content */}
              <div className='lg:w-1/2 p-6'>
                <h2 className='text-3xl font-bold mb-4'>{eventsData.title}</h2>
                <p className='text-gray-600 mb-4'>Date: {eventsData.date}</p>
                <p className='text-lg text-gray-700 mb-6'>
                  {eventsData.description}
                </p>
                {eventsData.registrationLink ? (
                  <a
                    href={eventsData.registrationLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200'>
                    Register Now
                  </a>
                ) : (
                  <button
                    disabled
                    className='px-6 py-3 bg-gray-400 text-white rounded-md cursor-not-allowed'>
                    Registration Coming Soon
                  </button>
                )}
              </div>

              {/* Image */}
              <div className='lg:w-1/2 flex justify-center'>
                <img
                  src={eventsData.image}
                  alt={eventsData.title}
                  className='w-80 h-80 object-cover rounded-md shadow-md'
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default Events;
