const eventsData = [
  {
    id: "auarskolt-2025",
    title: "Auårskolten 2025",
    date: "20.06.2025 - 22.06.2025",
    description: [
      "Velkommen til Bodø og Auårskolten 2025.",
      " Hvem kan melde seg på? Alle reflektanter, nåværende og tidligere medlemmer av Round Table eller Ladies Circle og deres romantiske involverte.",
    ],
    image:
      "https://pbs.twimg.com/profile_images/1288878760553598977/28xPdXH0_400x400.jpg",
    registrationLink:
      "https://www.letsreg.com/no/event/auarskolten_2025_20062025#init",
  },
  {
    id: "test1",
    title: "Annual Dinner",
    date: "January 20, 2024",
    description: [
      "A formal dinner event to celebrate our achievements and future goals.",
    ],
    image: "https://via.placeholder.com/150",
    registrationLink: null, // No registration link yet
  },
  {
    id: "test2",
    title: "Outdoor Retreat",
    date: "March 15, 2024",
    description: ["A refreshing retreat with outdoor activities and bonding."],
    image: "https://via.placeholder.com/150",
    registrationLink: null, // No registration link yet
  },
];

export default eventsData;
