import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/RT_SITELOGO.png";
import eventsData from "../data/eventsData";

const HomePage = () => {
  const closestEvent = eventsData[0];

  return (
    <main className='bg-gray-100 text-slate-950'>
      {/* Hero Section */}
      <section className='bg-slate-950 text-white py-20 px-6 text-center'>
        <div className='container mx-auto'>
          <h1 className='text-4xl font-bold mb-4'>
            Round table Norway - 37 Bodø
          </h1>
          <p className='text-lg mb-8'>
            Discover our events and learn about us.
          </p>
          <div className='space-x-4'>
            <Link
              to='/events'
              className='px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700'>
              View Events
            </Link>
            <Link
              to='/about'
              className='px-6 py-3 bg-gray-200 text-slate-950 rounded-md hover:bg-gray-300'>
              About Us
            </Link>
          </div>
        </div>
      </section>

      {/* Closest Event Section */}
      <section className='py-16 px-6'>
        <div className='container mx-auto text-center'>
          <h2 className='text-3xl font-bold mb-6'>{closestEvent.title}</h2>
          {closestEvent.description.map((line, lineIndex) => (
            <p className='text-lg text-gray-700' key={lineIndex}>
              {line}
            </p>
          ))}
          <p className='text-gray-600 mt-4'>Date:</p>
          <p className='text-gray-600 mb-6'>{closestEvent.date}</p>
          {closestEvent.registrationLink ? (
            <a
              href={closestEvent.registrationLink}
              target='_blank'
              rel='noopener noreferrer'
              className='px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200'>
              Register Now
            </a>
          ) : (
            <button
              disabled
              className='px-6 py-3 bg-gray-400 text-white rounded-md cursor-not-allowed'>
              Registration Coming Soon
            </button>
          )}
        </div>
      </section>

      {/* Event Highlights Section */}
      <section className='py-16 px-6 bg-gray-200'>
        <div className='container mx-auto text-center'>
          <h2 className='text-3xl font-bold mb-6'>Upcoming Events</h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
            {eventsData.map((event, index) => (
              <div key={index} className='bg-white p-6 rounded-lg shadow-md'>
                <h3 className='text-xl font-bold mb-2'>{event.title}</h3>
                <p className='text-gray-600 mb-4'>Date: {event.date}</p>
                <p className='text-gray-700'>{event.description}</p>
                <Link
                  to={`/events#${event.id}`}
                  className='mt-4 inline-block text-blue-600 hover:underline'>
                  Learn More
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomePage;
