import logo from "../assets/images/RT_SITELOGO.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { useState } from "react";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu visibility

  const isActive = (path) => location.pathname === path;

  return (
    <header className='bg-slate-950 text-white py-6 shadow-md font-inter'>
      <div className='container mx-auto flex flex-col sm:flex-row items-center justify-between px-4'>
        {/* Logo */}
        <div className='flex justify-center sm:justify-start items-center w-full sm:w-auto'>
          {/* Logo is centered on mobile and left-aligned on larger screens */}
          <Link to='/' className='flex items-center focus:outline-none'>
            <img
              src={logo}
              alt='Logo'
              className='h-22 sm:h-24 lg:h-32 w-auto' // Logo size
            />
          </Link>
        </div>

        {/* Hamburger Icon for Mobile */}
        <button
          className='sm:hidden text-white font-bold text mt-4 focus:outline-none'
          onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d={
                isMenuOpen
                  ? "M6 18L18 6M6 6l12 12" // Close icon
                  : "M4 6h16M4 12h16M4 18h16" // Hamburger menu icon
              }
            />
          </svg>
        </button>

        {/* Navigation */}
        <nav
          className={`${
            isMenuOpen ? "block" : "hidden"
          } sm:block w-full sm:w-auto mt-4 sm:mt-0`}>
          <ul className='flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6'>
            <li>
              <button
                onClick={() => navigate("/")}
                className={`px-6 py-3 rounded-md ${
                  isActive("/")
                    ? "bg-white text-black"
                    : "bg-transparent text-white"
                } focus:outline-none hover:bg-gray-100 hover:text-black`}>
                Home
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/events")}
                className={`px-6 py-3 rounded-md ${
                  isActive("/events")
                    ? "bg-white text-black"
                    : "bg-transparent text-white"
                } focus:outline-none hover:bg-gray-100 hover:text-black`}>
                Events
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/about")}
                className={`px-6 py-3 rounded-md ${
                  isActive("/about")
                    ? "bg-white text-black"
                    : "bg-transparent text-white"
                } focus:outline-none hover:bg-gray-100 hover:text-black`}>
                About Us
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
