import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/RT_SITELOGO.png"; // Adjust the path if needed

const Footer = () => {
  return (
    <footer className='bg-slate-800 text-white py-10'>
      <div className='container mx-auto relative flex items-center justify-center'>
        {/* Logo */}
        <div className='absolute left-20'>
          <img src={logo} alt='Logo' className='h-20 w-auto object-contain' />
        </div>

        {/* Navigation Links */}
        <nav>
          <ul className='flex flex-col items-center space-y-4'>
            <li>
              <Link
                to='/'
                className='text-lg hover:text-gray-300 hover:underline focus:outline-none'>
                Home
              </Link>
            </li>
            <li>
              <Link
                to='/events'
                className='text-lg hover:text-gray-300 hover:underline focus:outline-none'>
                Events
              </Link>
            </li>
            <li>
              <Link
                to='/contact'
                className='text-lg hover:text-gray-300 hover:underline focus:outline-none'>
                About Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
